import '../styles/footer.css'
const Footer = () => {
    return (
        <div className={'footer-wrapper'}>
            <div className={'footer-top'}>
                <img src={'images/Logo-white.png'} alt={'Logo'} />
            </div>
            <div className={'footer-middle'}>
                <div>
                    <a href={'https://www.linkedin.com/company/nimber/'}>
                        <img src={'images/icons/linkedin.png'} alt={'LinkedIn'} />
                    </a>
                    <a href={'https://www.instagram.com/nimber.tech/'}>
                        <img src={'images/icons/instagram.png'} alt={'Instagram'} />
                    </a>
                </div>
                <div>
                    <span>geral@nimber.pt</span>
                    <p>Rua do Polo Norte Nº14 - piso 1.2</p>
                    <p>1990-266 - Parque das Nações - Lisboa</p>
                </div>
            </div>
            <div className={'footer-bottom'}>
                <span>Copyright © Nimber All Rights Reserved</span>
                <div className={'footer-design'}>
                    <span>Designed by: <span className={'footer-special'}>Nimber</span></span>
                    <span>Developed by: <span className={'footer-special'}>Nimber</span></span>
                </div>
            </div>
        </div>
    )
}

export default Footer
