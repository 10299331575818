import React from 'react';
import PeopleAreKeyCard from './PeopleAreKeyCard';
import '../styles/peopleAreKeyCardContainer.css';

const PeopleAreKeyCardContainer = () => {
    const cardData = [
        {
            id: 1,
            imageSrc: '/images/team/Rui Neves.jpg',
            title: 'Rui Neves',
            jobRole: 'CEO',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 2,
            imageSrc: '/images/team/Mário Caldeano.jpg',
            title: 'Mario Caldeano',
            jobRole: 'Chief operating Officier',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 3,
            imageSrc: '/images/team/Ana Godinho.jpg',
            title: 'Ana Godinho',
            jobRole: 'Certified Accountant',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 4,
            imageSrc: '/images/team/Inês Almas.jpg',
            title: 'Inês Almas',
            jobRole: 'Project Manager',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 5,
            imageSrc: '/images/team/Rita Silva.jpg',
            title: 'Rita Silva',
            jobRole: 'HR Manager',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 6,
            imageSrc: '/images/team/Filipa Mourinho.jpg',
            title: 'Filipa Mourinho',
            jobRole: 'Administrative Officer',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 7,
            imageSrc: '/images/team/Catarina Santos.jpg',
            title: 'Catarina Santos',
            jobRole: 'Business Manager',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 8,
            imageSrc: '/images/team/Francisco Almeida-v2.jpg',
            title: 'Francisco Almeida',
            jobRole: 'Senior Business Manager',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 9,
            imageSrc: '/images/team/Cláudia Lira.jpg',
            title: 'Cláudia Lira',
            jobRole: 'IT Recruiter',
            linkedinUrl: 'https://www.linkedin.com/',
        },
        {
            id: 10,
            imageSrc: '/images/team/Renata Martins.jpg',
            title: 'Renata Martins',
            jobRole: 'IT Recruiter',
            linkedinUrl: 'https://www.linkedin.com/',
        },
    ];

    return (
        <div className="people-are-key-card-container">
            {cardData.map((card) => (
                <PeopleAreKeyCard
                    key={card.id}
                    imageSrc={card.imageSrc}
                    title={card.title}
                    jobRole={card.jobRole}
                    linkedinUrl={card.linkedinUrl}
                />
            ))}
        </div>
    );
};

export default PeopleAreKeyCardContainer;
